import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const aboutQuery = graphql`
  query aboutQuery {
    wpPage(databaseId: { eq: 47 }) {
      title
      content
      aboutUs {
        subtitle
        section1Title
        section2Title
        section3Title
        teamMembers {
          avatar {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 253)
              }
            }
          }
          name
          researchGateUrl
          linkedinUrl
          content
        }
        memberships {
          content
        }
        membershipLogos {
          link
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 220)
              }
            }
          }
        }
      }
    }
  }
`

export default function About({ data }) {
  const paraskevopoulos = data.wpPage.aboutUs.teamMembers[0]
  const stefanidou = data.wpPage.aboutUs.teamMembers[1]
  const markogiannaki = data.wpPage.aboutUs.teamMembers[2]

  return (
    <Layout
      bodyClass="about"
      heroTitle={data.wpPage.title}
      heroText={data.wpPage.aboutUs.subtitle}
      bcText="ABOUT US"
    >
      <section className="about-whoweare">
        <Container>
          <Row>
            <Col sm={12}>
              <span className="red-subtitle">
                {data.wpPage.aboutUs.section1Title}
              </span>
              <div className="who-content">{parse(data.wpPage.content)}</div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="about-ourteam ourteam1">
        <Container>
          <Row>
            <Col sm={12}>
              <span className="red-subtitle">
                {data.wpPage.aboutUs.section2Title}
              </span>
              <div className="team-cv">
                <div className="team-main">
                  {paraskevopoulos.avatar && (
                    <GatsbyImage
                      image={getImage(paraskevopoulos.avatar.localFile)}
                      alt="Elias Paraskevopoulos"
                    />
                  )}
                  <div className="cv-specs">
                    <h3>{parse(paraskevopoulos.name)}</h3>
                    <ul>
                      <li>
                        <a href={paraskevopoulos.researchGateUrl}>
                          ResearchGate{" "}
                          <span className="fa fa-chevron-right"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-bio">
                  <p>{paraskevopoulos.content}</p>
                  <span className="cv-more">
                    <span className="cv-label">Read more</span>
                    <span className="cv-sign">+</span>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="about-ourteam ourteam2 triggerclass">
        <Container>
          <Row>
            <Col sm={12} lg={6}>
              <div className="team-cv">
                <div className="team-main">
                  {stefanidou.avatar && (
                    <GatsbyImage
                      image={getImage(stefanidou.avatar.localFile)}
                      alt="Sotiria Stefanidou"
                    />
                  )}
                  <div className="cv-specs">
                    <h3>{parse(stefanidou.name)}</h3>
                    <ul>
                      <li>
                        <a href={stefanidou.researchGateUrl}>
                          ResearchGate{" "}
                          <span className="fa fa-chevron-right"></span>
                        </a>
                      </li>
                      <li>
                        <a href={stefanidou.linkedInUrl}>
                          LinkedIn <span className="fa fa-chevron-right"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-bio">
                  <p>{stefanidou.content}</p>
                  <span className="cv-more">
                    <span className="cv-label">Read more</span>
                    <span className="cv-sign">+</span>
                  </span>
                </div>
              </div>
            </Col>
            {false && (
            <Col sm={12} lg={6}>
              <div className="team-cv">
                <div className="team-main">
                  {markogiannaki.avatar && (
                    <GatsbyImage
                      image={getImage(markogiannaki.avatar.localFile)}
                      alt="Olga Markogiannaki"
                    />
                  )}
                  <div className="cv-specs">
                    <h3>{parse(markogiannaki.name)}</h3>
                    <ul>
                      <li>
                        <a href={markogiannaki.researchGateUrl}>
                          ResearchGate{" "}
                          <span className="fa fa-chevron-right"></span>
                        </a>
                      </li>
                      <li>
                        <a href={markogiannaki.linkedInUrl}>
                          LinkedIn <span className="fa fa-chevron-right"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-bio">
                  <p>{markogiannaki.content}</p>
                  <span className="cv-more">
                    <span className="cv-label">Read more</span>
                    <span className="cv-sign">+</span>
                  </span>
                </div>
              </div>
            </Col>
            )}
          </Row>
        </Container>
      </section>
      <section className="about-ourteam ourteam3">
        <Container>
          <Row>
            {data.wpPage.aboutUs.teamMembers &&
              data.wpPage.aboutUs.teamMembers.slice(3).map(member => (
                <Col sm={12}>
                  <div className="team-cv">
                    <div className="team-other">
                      {member.avatar && (
                        <GatsbyImage
                          image={getImage(member.avatar.localFile)}
                          alt={member.name}
                        />
                      )}
                      <div className="team-bio">
                        <h3>{member.name}</h3>
                        <p>{member.content}</p>
                        <span className="cv-more">
                          <span className="cv-label">Read more</span>
                          <span className="cv-sign">+</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </section>
      <section className="about-memberships">
        <Container>
          <Row>
            <Col sm={12}>
              <span className="red-subtitle">
                {data.wpPage.aboutUs.section3Title}
              </span>
              <ul>
                {data.wpPage.aboutUs.memberships &&
                  data.wpPage.aboutUs.memberships.map(membership => (
                    <li>{parse(membership.content)}</li>
                  ))}
              </ul>
              {data.wpPage.aboutUs.membershipLogos &&
                data.wpPage.aboutUs.membershipLogos.map(logo => (
                  <GatsbyImage
                    image={getImage(logo.logo.localFile)}
                    alt="logo image"
                    className="membership-logo"
                  />
                ))}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}
